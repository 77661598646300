import Axios from "axios";

export const url = process.env.REACT_APP_ENDPOINTS_BASE_URL;

/// get stores
export const getAllStoresApi = (name) => {
  let result = Axios.get(`/store?name=${name}`);
  return result;
};

/// add store
export const addStoreApi = (data) => {
  const formData = new FormData();
  Object.keys(data)?.forEach((key) => {
    formData.append(key, data[key]);
  });
  let result = Axios.post("/store", formData);
  return result;
};

/// get store
export const getStoreApi = (id) => {
  let result = Axios.get(`/store/${id}`);
  return result;
};

/// delete store
export const deleteStoreApi = (id) => {
  let result = Axios.delete(`/store/${id}`);
  return result;
};

/// edit store

export const editStoreApi = (id, data) => {
  const formData = new FormData();
  Object.keys(data)?.forEach((key) => {
    formData.append(key, data[key]);
  });
  let result = Axios.put(`/store/${id}`, formData);
  return result;
};

/// get products
export const getProductsApi = () => {
  let result = Axios.get("/product");
  return result;
};

/// add new link
export const addNewLinkApi = (id, data) => {
  let result = Axios.patch(`/store/${id}`, data);
  return result;
};

///delete linked product

export const deleteLinkedProductApi = (store, product) => {
  let result = Axios.delete(`/store/${store}/product/${product}`);
  return result;
};

///edit linked product

export const editLinkedProductApi = (store, product, data) => {
  let result = Axios.put(`/store/${store}/product/${product}`, data);
  return result;
};
