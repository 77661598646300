import "./App.css";
import "./style/globals.css";
import {
  Navigate,
  RouterProvider,
  createBrowserRouter,
  redirect,
} from "react-router-dom";

import Home from "./pages/home/Home";
import Login from "./pages/login/Login";
import Products from "./pages/products/Products";
import Settings from "./pages/settings/Settings";
import Orders from "./pages/orders/Orders";
import Users from "./pages/users/Users";
import Employees from "./pages/employees/Employees";
import Main from "./pages/Main/Main";
import Catalogs from "./pages/catalogs/Catalogs";
import Banner from "./pages/banner/Banner";
import Tabels from "./pages/tabels/Tabels";

import { useContext, useEffect, useState } from "react";

import Bills from "./pages/bills/Bills";
import { LanguageContext } from "./context/LanguageContext";
import { Stores } from "./pages/stores/Stores";

const App = () => {
  const [toggleSideBar, setToggleSideBar] = useState(false);
  const isAuthenticated = localStorage.getItem("user");
  const { direction } = useContext(LanguageContext);

  useEffect(() => {
    document.documentElement.style.setProperty("--direction", direction);
  }, [direction]);

  const toggleSidebarFunc = (val) => {
    setToggleSideBar(val);
  };

  const router = createBrowserRouter([
    {
      path: "/",
      loader: () => redirect("/menu"),
    },
    {
      path: "/admin/login",
      element: !isAuthenticated ? <Login /> : <Navigate to='/admin' />,
    },
    {
      path: "/admin",
      element: isAuthenticated ? (
        <Main toggleSideBar={toggleSideBar} />
      ) : (
        <Navigate to='/admin/login' />
      ),
      children: [
        { path: "/admin", element: <Home /> },
        { path: "/admin/settings", element: <Settings /> },
        { path: "/admin/employees", element: <Employees /> },
        { path: "/admin/products", element: <Products /> },
        { path: "/admin/catalogs", element: <Catalogs /> },
        { path: "/admin/users", element: <Users /> },
        { path: "/admin/tabels", element: <Tabels /> },
        { path: "/admin/bills", element: <Bills /> },
        { path: "/admin/orders", element: <Orders /> },
        { path: "/admin/banner", element: <Banner /> },
        {
          path: "/admin/stores",
          element: <Stores toggleSideBar={toggleSidebarFunc} />,
        },
      ],
    },
  ]);

  return <RouterProvider router={router} />;
};
export default App;
