import { useState } from "react";
import "./Stores.css";
import {
  getAllStoresApi,
  addStoreApi,
  getStoreApi,
  getProductsApi,
  addNewLinkApi,
  deleteLinkedProductApi,
  editLinkedProductApi,
  deleteStoreApi,
  editStoreApi,
} from "../../APIs/StoresAPIs";
import { url } from "../../APIs/StoresAPIs";
import SearchOutlinedIcon from "@mui/icons-material/SearchOutlined";
import BorderColorOutlinedIcon from "@mui/icons-material/BorderColorOutlined";
import DeleteOutlineOutlinedIcon from "@mui/icons-material/DeleteOutlineOutlined";
import ArrowBackOutlinedIcon from "@mui/icons-material/ArrowBackOutlined";
import DoneIcon from "@mui/icons-material/CheckCircleOutlined";
import GppBadIcon from "@mui/icons-material/GppBad";
import Store from "./../../assets/stores.png";
import * as React from "react";
import { Box, Modal, CircularProgress } from "@mui/material";
import { message } from "antd";
import { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { LanguageContext } from "../../context/LanguageContext";
import { useContext } from "react";

export const Stores = (props) => {
  const [addMaterialBtn, setAddMaterialBtn] = useState(false);
  const [addMaterialOpen, setAddMaterialOpen] = useState(false);
  const [addNewLinkOpen, setAddNewLinkOpen] = useState(false);
  const [imageInput, setImageInput] = useState("الصورة");
  const [searchByName, setSearchByName] = useState("");
  const [t] = useTranslation();
  const { direction } = useContext(LanguageContext);
  ///add store error handling states
  const [addNameError, setAddNameError] = useState(false);
  const [addUnitError, setAddUnitError] = useState(false);
  const [addQuantityError, setAddQuantityError] = useState(false);
  const [addImageError, setAddImageError] = useState(false);
  ///add & edit store states
  const [addName, setAddName] = useState("");
  const [addNameEn, setAddNameEn] = useState("");
  const [addUnit, setAddUnit] = useState("");
  const [addUnitEn, setAddUnitEn] = useState("");
  const [addQuantity, setAddQuantity] = useState("");
  const [addImage, setAddImage] = useState(null);
  const [addStoreLoading, setAddStoreLoading] = useState(false);
  const [store, setStore] = useState({});
  const [linkedProducts, setLinkedProducts] = useState([]);
  const [allProducts, setAllProducts] = useState([]);
  const [editStoreOpen, setEditStoreOpen] = useState(false);
  const [editStoreLoading, setEditStoreLoading] = useState(false);
  const [editImage, setEditImage] = useState(null);
  const [editName, setEditName] = useState("");
  const [editNameEn, setEditNameEn] = useState("");
  const [editQuantity, setEditQuantity] = useState("");
  const [editUnit, setEditUnit] = useState("");
  const [editUnitEn, setEditUnitEn] = useState("");
  const [editStoreObj, setEditStoreObj] = useState({});
  const [ediStoreSentObj, setEditStoreSentObj] = useState({});
  const [confirmEditStoreOpen, setConfirmEditStoreOpen] = useState(false);
  const [editDisQuantity, setEditDisQuantity] = useState("");
  const [quantityError, setQuantityError] = useState(false);
  ///add link states
  const [addProductError, setAddProductError] = useState(false);
  const [addProductPercentError, setAddProductPercentError] = useState(false);
  const [addMaterialPercentError, setAddMaterialPercentError] = useState(false);
  const [productId, setProductId] = useState("");
  const [storeId, setStoreId] = useState("");
  const [productPercent, setProductPercent] = useState("");
  const [materialPercent, setMaterialPercent] = useState("");
  const [addLinkLoading, setAddLinkLoading] = useState(false);
  //delete link && store
  const [deleteLinkOpen, setDeleteLinkOpen] = useState(false);
  const [deleteLinkLoading, setDeleteLinkLoading] = useState(false);
  const [linkedProductId, setLinkedProductId] = useState("");
  const [stores, setStores] = useState([]);
  const [deleteStoreOpen, setDeleteStoreOpen] = useState(false);
  const [deletedStoreId, setDeletedStoreId] = useState("");
  const [deleteStoreLoading, setDeleteStoreLoading] = useState(false);
  ///edit link states
  const [editLinkOpen, setEditLinkOpen] = useState(false);
  const [confirmEditLinkLoading, setConfirmEditLinkLoading] = useState(false);
  const [editProductPercent, setEditProductPercent] = useState("");
  const [editMaterialPercent, setEditMaterialPercent] = useState("");
  const [editProductName, setEditProductName] = useState("");
  const [editLinkObj, setEditLinkObj] = useState({});
  const [editLinkSentObj, setEditLinkSentObj] = useState({});
  const [confirmEditLinkOpen, setConfirmEditLinkOpen] = useState(false);
  /// success states
  const [successModalOpen, setSuccessModalOpen] = useState(false);
  const [addMaterialMsg, setAddMaterialMsg] = useState("");
  const [editMaterialMsg, setEditMaterialMsg] = useState("");
  const [addLinkMsg, setAddLinkMsg] = useState("");
  const [editLinkMsg, setEditLinkMsg] = useState("");
  const [deleteLinkMsg, setDeleteLinkMsg] = useState("");
  const [deleteStoreMsg, setDeleteStoreMsg] = useState("");
  const [successModalIdentify, setSuccessModalIdentify] = useState("");
  const [toggleSuccessError, setToggleSuccessError] = useState("success");

  /// useEffects
  useEffect(() => {
    getAllStoresFunc();
  }, [searchByName]);

  useEffect(() => {
    getAllProductsFunc();
  }, []);

  useEffect(() => {
    const handleBackButton = () => {
      console.log("Back button was pressed");
      // Handle back button press here
      setAddMaterialBtn(false);
      props.toggleSideBar(false);
    };

    window.addEventListener("popstate", handleBackButton);

    return () => {
      window.removeEventListener("popstate", handleBackButton);
    };
  }, []);

  /// get all stores func
  const getAllStoresFunc = async () => {
    try {
      let response = await getAllStoresApi(searchByName);
      let data = await response?.data?.data;
      setStores([...data]);
      //console.log("stores",response?.data)
    } catch (e) {}
  };

  /// get all Products
  const getAllProductsFunc = async () => {
    try {
      let response = await getProductsApi();
      let data = await response?.data?.data;
      setAllProducts([...data]);
    } catch (e) {}
  };

  /// get store data

  const getStoreFunc = async (id) => {
    setAddMaterialBtn(true);
    props.toggleSideBar(true);
    setStoreId(id);
    try {
      let response = await getStoreApi(id);
      setStore(response?.data?.data);
      setLinkedProducts([...response?.data?.data?.products]);
      // console.log("storeData", response);
    } catch (error) {}
  };

  //image onChange handler
  const handleUploadImage = (e) => {
    const file = e.target.files[0];
    setAddImage(file);
    setAddImageError(false);
    console.log("img", file.name);
    setImageInput(file.name);
  };

  //edit image onChange handler
  const handleUploadEditedImage = (e) => {
    const file = e.target.files[0];
    setEditImage(file);

    setImageInput(file.name);
  };

  /// edit store change handler
  const editStoreOnChange = (e) => {
    const { name, value } = e.target;
    setEditStoreObj((prevValues) => ({
      ...prevValues,
      [name]: value,
    }));
    switch (name) {
      case "name":
        setEditName(value);
        break;
      case "quantity":
        setEditQuantity(value);
        if (value === "") {
          setQuantityError(false);
        }
        break;
      case "disQuantity":
        setEditDisQuantity(value);
        if (value === "") {
          setQuantityError(false);
        }
        break;
      case "measruingUnit":
        setEditUnit(value);
        break;
      default:
        break;
    }
  };

  ///edit link change
  const editLinkChange = (e) => {
    let { name, value } = e.target;
    setEditLinkObj((prevValues) => ({
      ...prevValues,
      [name]: value,
    }));
    switch (name) {
      case "from":
        setEditProductPercent(value);
        break;
      case "to":
        setEditMaterialPercent(value);
        break;

      default:
        break;
    }
  };
  const addMaterialModalClose = () => {
    setAddMaterialOpen(false);
    setAddImageError(false);
    setAddNameError(false);
    setAddQuantityError(false);
    setAddUnitError(false);
    setAddImage(null);
    setImageInput("الصورة");
    setAddUnit("");
    setAddQuantity("");
    setAddName("");
    setAddNameEn("");
  };

  /// add material submit handler
  const addMaterialSubmit = async (e) => {
    e.preventDefault();
    if (!addImage || !addName || !addQuantity || !addUnit) {
      if (addName === "") {
        setAddNameError(true);
      }
      if (addUnit === "") {
        setAddUnitError(true);
      }
      if (addQuantity === "") {
        setAddQuantityError(true);
      }
      if (addImage === null) {
        setAddImageError(true);
      }
    } else {
      setAddStoreLoading(true);
      try {
        let response = await addStoreApi({
          name: addName + (addNameEn ? `-${addNameEn}` : ""),
          quantity: addQuantity,
          measruingUnit: addUnit + (addUnitEn ? `-${addUnitEn}` : ""),
          image: addImage,
        });
        setToggleSuccessError("success");
        setSuccessModalIdentify("add-material");
        setAddMaterialMsg(response?.data?.message);
        setSuccessModalOpen(true);

        setAddMaterialOpen(false);
        setAddStoreLoading(false);
        setAddImage(null);
        setImageInput("الصورة");
        setAddUnit("");
        setAddUnitEn("");
        setAddQuantity("");
        setAddName("");
        setAddNameEn("");
        getAllStoresFunc();
      } catch (error) {
        setToggleSuccessError("error");
        setSuccessModalIdentify("add-material");

        setAddStoreLoading(false);
        let errorsArr = [];

        let errors = await error?.response?.data?.errors;
        errors.map((e) => {
          let error = e.field + " " + e.message;
          errorsArr.push(error);
        });

        setAddMaterialMsg(errorsArr.join());
        setSuccessModalOpen(true);
      }
    }
  };

  /// add link submit handler
  const addNewLinkSubmit = async (e) => {
    e.preventDefault();
    if (!productId || !productPercent || !materialPercent) {
      if (productId === "") {
        setAddProductError(true);
      }
      if (productPercent === "") {
        setAddProductPercentError(true);
      }
      if (materialPercent === "") {
        setAddMaterialPercentError(true);
      }
    } else {
      setAddLinkLoading(true);
      try {
        let response = await addNewLinkApi(storeId, {
          product: productId,
          percent: { from: productPercent, to: materialPercent },
        });

        setAddNewLinkOpen(false);
        setAddLinkLoading(false);
        setSuccessModalIdentify("add-link");
        setAddLinkMsg(response?.data?.message);
        setSuccessModalOpen(true);
        //message.success(response?.data?.message);
        getStoreFunc(storeId);
        setProductPercent("");
        setMaterialPercent("");
        setProductId("");
      } catch (error) {
        setToggleSuccessError("error");
        setSuccessModalIdentify("add-link");
        setAddLinkLoading(false);
        setAddLinkMsg("لم يتم إضافة الربط");
        setSuccessModalOpen(true);
        // let errorsArr = [];

        // let errors = await error?.response?.data?.errors;
        // errors.map((e) => {
        //   let error = e.field + " " + e.message;
        //   errorsArr.push(error);
        // });
        // message.error(errorsArr.join());
      }
    }
  };

  /// delete linked product
  const deleteLinkedProductFunc = async () => {
    setDeleteLinkLoading(true);
    try {
      let response = await deleteLinkedProductApi(storeId, linkedProductId);

      setSuccessModalIdentify("delete-link");
      setDeleteLinkMsg("تم الحذف بنجاح");
      setSuccessModalOpen(true);
      setDeleteLinkOpen(false);
      setLinkedProductId("");
      setDeleteLinkLoading(false);
      getStoreFunc(storeId);
    } catch (error) {
      setToggleSuccessError("error");
      setSuccessModalIdentify("delete-link");
      setDeleteLinkLoading(false);
      setDeleteLinkMsg("لم يتم الحذف");
      setSuccessModalOpen(true);
    }
  };

  ///edit link func
  const editLinkFunc = () => {
    setConfirmEditLinkOpen(true);
    // let obj = {};
    // let subObj = {};
    // if (productId) {
    //   obj["product"] = productId;
    // }

    // Object.keys(editLinkObj)?.forEach((key) => {
    //   const value = editLinkObj[key];
    //   if (value) {
    //     subObj[key] = value;
    //   }
    //   obj["percent"] = subObj;
    //   setEditLinkSentObj(obj);
    // });
  };
  /// confirm edit linked product
  const confirmEditLinkFunc = async () => {
    setConfirmEditLinkLoading(true);
    try {
      let response = await editLinkedProductApi(storeId, linkedProductId, {
        product: productId,
        percent: {
          from: editProductPercent,
          to: editMaterialPercent,
        },
      });
      setSuccessModalIdentify("edit-link");
      setEditLinkMsg(response?.data?.message);
      setSuccessModalOpen(true);

      setConfirmEditLinkLoading(false);
      setConfirmEditLinkOpen(false);
      setEditLinkOpen(false);
      getStoreFunc(storeId);
      setEditLinkSentObj({});
      setEditLinkObj({});
    } catch (error) {
      setToggleSuccessError("error");
      setSuccessModalIdentify("edit-link");
      setConfirmEditLinkLoading(false);
      let errorsArr = [];

      let errors = await error?.response?.data?.errors;
      errors.map((e) => {
        let error = e.field + "  " + e.message;
        errorsArr.push(error);
      });

      setEditLinkMsg(errorsArr.join());
      setSuccessModalOpen(true);
    }
  };

  /// confirm delete store
  const confirmDeleteStoreFunc = async () => {
    setDeleteStoreLoading(true);
    try {
      let response = await deleteStoreApi(deletedStoreId);
      setDeleteStoreLoading(false);
      setDeleteStoreOpen(false);
      setSuccessModalIdentify("delete-store");
      setToggleSuccessError("success");
      setDeleteStoreMsg("تم الحذف بنجاح");
      setSuccessModalOpen(true);
      getAllStoresFunc();
    } catch (error) {
      setDeleteStoreLoading(false);
      setDeleteStoreOpen(false);
      setSuccessModalIdentify("delete-store");
      setToggleSuccessError("error");
      setDeleteStoreMsg("لم يتم الحذف");
      setSuccessModalOpen(true);
    }
  };

  /// edit store func
  const editStoreFunc = () => {
    if (editStoreObj["quantity"] && editStoreObj["disQuantity"]) {
      setQuantityError(true);
    } else {
      setQuantityError(false);

      let obj = {};
      if (editImage) {
        obj["image"] = editImage;
      }
      if (editNameEn) {
        obj["name"] =
          editName.split("-")[0] + (editNameEn ? `-${editNameEn}` : "");
      }
      if (editUnitEn) {
        obj["measruingUnit"] =
          editUnit.split("-")[0] + (editUnitEn ? `-${editUnitEn}` : "");
      }
      Object.keys(editStoreObj)?.forEach((key) => {
        const value = editStoreObj[key];
        if (value) {
          if (key === "name" && editNameEn) {
            obj[key] = value + (editNameEn ? `-${editNameEn}` : "");
          } else if (key === "measruingUnit" && editUnitEn) {
            obj[key] = value + (editUnitEn ? `-${editUnitEn}` : "");
          } else {
            obj[key] = value;
          }
          // obj[key] = value;
        }
      });

      setEditStoreSentObj(obj);
      setConfirmEditStoreOpen(true);
    }
  };

  /// confirm edit store func

  const confirmEditStoreFunc = async () => {
    setEditStoreLoading(true);
    try {
      let response = await editStoreApi(storeId, ediStoreSentObj);
      setEditStoreOpen(false);
      setConfirmEditStoreOpen(false);
      setEditStoreLoading(false);
      setSuccessModalIdentify("edit-store");
      setToggleSuccessError("success");
      setEditMaterialMsg(response?.data?.message);
      setSuccessModalOpen(true);
      getAllStoresFunc();
      setEditDisQuantity("");
      setEditStoreObj({});
      setEditStoreSentObj({});
      setEditImage(null);
    } catch (error) {
      setToggleSuccessError("error");
      setSuccessModalIdentify("edit-store");
      setEditStoreLoading(false);
      let errorsArr = [];

      let errors = await error?.response?.data?.errors;
      errors.map((e) => {
        let error = e.field + "  " + e.message;
        errorsArr.push(error);
      });

      setEditMaterialMsg(errorsArr.join());
      setSuccessModalOpen(true);
    }
  };

  return (
    <>
      <div className='stores-main' style={{ padding: "15px 20px" }}>
        {addMaterialBtn ? (
          <ArrowBackOutlinedIcon
            onClick={() => {
              setAddMaterialBtn(false);
              props.toggleSideBar(false);
            }}
            style={{ cursor: "pointer" }}
          />
        ) : null}
        <div className='stores-header-div'>
          {addMaterialBtn ? (
            <>
              <button
                className='stores-header-btn'
                onClick={() => setAddNewLinkOpen(true)}
              >
                {t("add")}
              </button>
              <div className='stores-header-data-div' style={{}}>
                <div className='stores-header-txt-div'>
                  <p>{t("material")}:</p>
                  <p>{store?.name}</p>
                </div>
                <div className='stores-header-txt-div'>
                  <p>{t("quantity")}:</p>
                  <p>
                    {parseFloat(
                      (store?.remainingPercentage * store?.quantity) / 100
                    ).toFixed(2)}{" "}
                    {store?.measruingUnit}
                  </p>
                </div>
                <div className='stores-header-txt-div'>
                  <p>{t("ratio")}:</p>
                  <p>{store?.remainingPercentage}%</p>
                </div>
              </div>
            </>
          ) : (
            <>
              <button
                className='stores-header-btn'
                onClick={() => setAddMaterialOpen(true)}
              >
                {t("add")}
              </button>
              <div className='stores-search-div'>
                <input
                  type='search'
                  className='stores-search-input'
                  placeholder={t("search")}
                  value={searchByName}
                  onChange={(e) => setSearchByName(e.target.value)}
                />
                <SearchOutlinedIcon className='stores-search-icon' />
              </div>{" "}
            </>
          )}
        </div>
        {addMaterialBtn ? (
          <>
            {" "}
            <div className='stores-table'>
              <div style={{ minWidth: "100%" }}>
                <div className='stores-table-head-row'>
                  <div
                    style={{
                      fontWeight: "600",
                      width: "37%",
                    }}
                    className='stores-table-col'
                  >
                    {t("materialsRatio")}
                  </div>
                  <div
                    style={{
                      fontWeight: "600",
                      width: "37%",
                    }}
                    className='stores-table-col'
                  >
                    {t("productRatio")}
                  </div>
                  <div
                    style={{ width: "26%" }}
                    className='stores-table-col'
                  ></div>
                </div>
              </div>
              <div
                style={{
                  minWidth: "100%",
                  maxHeight: "400px",
                  overflow: "auto",
                }}
              >
                {linkedProducts?.map((product) => (
                  <div className='stores-table-row'>
                    <div className='stores-table-col' style={{ width: "37%" }}>
                      {product?.product?.name}
                    </div>
                    <div className='stores-table-col' style={{ width: "37%" }}>
                      {product?.percent?.from}:{product?.percent?.to}
                    </div>
                    <div style={{ width: "26%", display: "flex" }}>
                      <span
                        className='stores-table-edit'
                        onClick={() => {
                          setEditLinkOpen(true);
                          setLinkedProductId(product?._id);
                          setEditProductName(product?.product?.name);
                          setProductId(product?.product?.id);
                          setEditProductPercent(product?.percent?.from);
                          setEditMaterialPercent(product?.percent?.to);
                        }}
                      >
                        <BorderColorOutlinedIcon style={{ fontSize: "20px" }} />
                      </span>
                      <span
                        className='stores-table-edit'
                        onClick={() => {
                          setDeleteLinkOpen(true);
                          setLinkedProductId(product?._id);
                        }}
                      >
                        <DeleteOutlineOutlinedIcon
                          style={{
                            fontSize: "20px",
                            color: "rgba(188, 42, 70, 1)",
                          }}
                        />
                      </span>
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </>
        ) : (
          <div className='stores-cards-div'>
            {stores?.map((store) => (
              <div
                className='stores-card'
                // onClick={() => {
                //   getStoreFunc(store?._id);
                // }}
                style={{ position: "relative" }}
              >
                <div
                  style={{
                    width: "fit-content",
                    display: "flex",
                    position: "absolute",
                    top: "0px",
                    left: "0px",
                  }}
                >
                  <span
                    className='stores-table-edit'
                    onClick={() => {
                      setEditStoreOpen(true);
                      setImageInput(store?.image);
                      setEditQuantity(
                        parseFloat(
                          (store?.remainingPercentage * store?.quantity) / 100
                        ).toFixed(2)
                      );
                      setEditUnit(store?.measruingUnit.split("-")[0]);
                      setEditUnitEn(store?.measruingUnit.split("-")[1]);
                      setEditName(store?.name.split("-")[0]);
                      setEditNameEn(store?.name.split("-")[1]);
                      setStoreId(store?._id);
                    }}
                    style={{ margin: "0px 5px 0px 0px" }}
                  >
                    <BorderColorOutlinedIcon style={{ fontSize: "20px" }} />
                  </span>
                  <span
                    className='stores-table-edit'
                    onClick={() => {
                      setDeletedStoreId(store?._id);
                      setDeleteStoreOpen(true);
                    }}
                    style={{ margin: "0px 5px 0px 0px" }}
                  >
                    <DeleteOutlineOutlinedIcon
                      style={{
                        fontSize: "20px",
                        color: "rgba(188, 42, 70, 1)",
                      }}
                    />
                  </span>
                </div>
                <img
                  src={process.env.REACT_APP_ASSETS_BASE_URL + store?.image}
                  onClick={() => {
                    getStoreFunc(store?._id);
                  }}
                  style={{ cursor: "pointer" }}
                  alt=''
                />
                <div className='stores-card-txt-div' style={{}}>
                  <p>{t("material")}:</p>
                  <p>{store?.name}</p>
                </div>
                <div className='stores-card-txt-div' style={{}}>
                  <p>{t("ratio")}:</p>
                  <p
                    style={{
                      color:
                        parseFloat(store?.remainingPercentage) >= 50
                          ? "black"
                          : "red",
                    }}
                  >
                    {store?.remainingPercentage.toFixed(2)}%
                  </p>
                </div>
                <div className='stores-card-txt-div' style={{}}>
                  <p>{t("total")}:</p>
                  <p>
                    {(store?.remainingPercentage * store?.quantity).toFixed(2) /
                      100}{" "}
                    {store?.measruingUnit}
                  </p>
                </div>
              </div>
            ))}
          </div>
        )}
      </div>

      {/******add new material modal***** */}
      <Modal
        open={addMaterialOpen}
        onClose={addMaterialModalClose}
        aria-labelledby='modal-modal-title'
        aria-describedby='modal-modal-description'
        className='add-material-modal'
        style={{ direction: direction }}
      >
        <Box
          sx={{
            boxShadow: 24,
            p: 4,
          }}
        >
          <div className='add-material-modal-body'>
            <form
              className='add-material-modal-body'
              onSubmit={addMaterialSubmit}
            >
              <input
                type='text'
                placeholder='الاسم'
                className='add-material-inputs'
                style={{ padding: "12px 10px" }}
                value={addName}
                onChange={(e) => {
                  setAddName(e.target.value);
                  if (e.target.value !== "") {
                    setAddNameError(false);
                  }
                }}
              />
              {addNameError && (
                <p style={{ color: "red" }}>من فضلك ادخل اسم المادة الخام</p>
              )}
              <input
                type='text'
                placeholder='الاسم بالانجليزية'
                className='add-material-inputs'
                style={{ padding: "12px 10px" }}
                value={addNameEn}
                onChange={(e) => {
                  setAddNameEn(e.target.value);
                }}
              />
              <div
                className='add-material-inputs'
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  padding: "7px 10px",
                }}
              >
                <p
                  style={{
                    color: "rgba(81, 81, 81, 1)",
                    fontSize: "14px",
                    fontWeight: "600",
                  }}
                >
                  {imageInput}
                </p>
                <input
                  type='file'
                  id='uploadImg'
                  accept='image/*'
                  style={{ display: "none" }}
                  onChange={handleUploadImage}
                />
                <label htmlFor='uploadImg' className='choose-img-btn'>
                  {t("chooseFile")}
                </label>
              </div>
              {addImageError && (
                <p style={{ color: "red" }}>من فضلك اختر صورة</p>
              )}
              <input
                type='number'
                placeholder='الكمية'
                className='add-material-inputs'
                style={{ padding: "12px 10px" }}
                value={addQuantity}
                onChange={(e) => {
                  setAddQuantity(e.target.value);
                  if (e.target.value !== "") {
                    setAddQuantityError(false);
                  }
                }}
              />
              {addQuantityError && (
                <p style={{ color: "red" }}>من فضلك ادخل الكمية</p>
              )}
              <input
                type='text'
                placeholder='وحدة القياس'
                className='add-material-inputs'
                style={{ padding: "12px 10px" }}
                value={addUnit}
                onChange={(e) => {
                  setAddUnit(e.target.value);
                  if (e.target.value !== "") {
                    setAddUnitError(false);
                  }
                }}
              />
              {addUnitError && (
                <p style={{ color: "red" }}>من فضلك ادخل وحدة القياس</p>
              )}
              <input
                type='text'
                placeholder='وحدة القياس بالانجليزية'
                className='add-material-inputs'
                style={{ padding: "12px 10px" }}
                value={addUnitEn}
                onChange={(e) => {
                  setAddUnitEn(e.target.value);
                }}
              />
              <div className='add-material-modal-btns-div'>
                <button type='htmlSubmit' className='add-material-modal-addBtn'>
                  إضافة
                  {addStoreLoading && (
                    <CircularProgress
                      sx={{ color: "rgba(255, 97, 29, 1)", margin: "0px 10px" }}
                      size={18}
                    />
                  )}
                </button>
                <button
                  className='add-material-modal-cancelBtn'
                  onClick={() => {
                    setAddMaterialOpen(false);
                    setAddImageError(false);
                    setAddNameError(false);
                    setAddQuantityError(false);
                    setAddUnitError(false);
                    setAddImage(null);
                    setImageInput("الصورة");
                    setAddUnit("");
                    setAddQuantity("");
                    setAddName("");
                    setAddNameEn("");
                  }}
                >
                  {t("cancel")}
                </button>
              </div>
            </form>
          </div>
        </Box>
      </Modal>

      {/******add new link modal***** */}
      <Modal
        open={addNewLinkOpen}
        onClose={() => {
          setAddNewLinkOpen(false);
          setProductPercent("");
          setProductId("");
          setMaterialPercent("");
          setAddMaterialPercentError(false);
          setAddProductError(false);
          setAddProductPercentError(false);
        }}
        aria-labelledby='modal-modal-title'
        aria-describedby='modal-modal-description'
        className='add-material-modal'
        style={{ direction: direction }}
      >
        <Box
          sx={{
            boxShadow: 24,
            p: 4,
          }}
        >
          <div className='add-material-modal-body'>
            <form onSubmit={addNewLinkSubmit}>
              <p
                style={{
                  fontSize: "17px",
                  fontWeight: "600",
                  marginBottom: "10px",
                }}
              >
                ربط مكون جديد بهذه المادة
              </p>
              <div className='add-link-modal-fields-div'>
                <select
                  className='add-link-modal-select'
                  onChange={(e) => {
                    setProductId(e?.target?.value);

                    setAddProductError(false);
                  }}
                >
                  <option value='' disabled selected>
                    {t("name")}{" "}
                  </option>
                  {allProducts?.map((product) => (
                    <option value={product?._id}>{product?.name}</option>
                  ))}
                </select>
              </div>
              {addProductError && (
                <p style={{ color: "red" }}>من فضلك اختر منتج</p>
              )}
              <p
                style={{
                  fontSize: "17px",
                  fontWeight: "600",
                  marginTop: "30px",
                }}
              >
                النسبة بين المنتج والمادة الخام
              </p>
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  marginTop: "10px",
                }}
              >
                <div style={{ width: "45%" }}>
                  <input
                    type='number'
                    placeholder='نسبة المنتج'
                    className='add-link-inputs'
                    value={productPercent}
                    onChange={(e) => {
                      setProductPercent(e?.target?.value);
                      if (e.target.value !== "") {
                        setAddProductPercentError(false);
                      }
                    }}
                  />
                  {addProductPercentError && (
                    <p style={{ color: "red" }}>من فضلك ادخل نسبة المنتج</p>
                  )}
                </div>
                <div style={{ width: "45%" }}>
                  <input
                    type='number'
                    placeholder='نسبة المادة الخام'
                    className='add-link-inputs'
                    value={materialPercent}
                    onChange={(e) => {
                      setMaterialPercent(e?.target?.value);
                      if (e.target.value !== "") {
                        setAddMaterialPercentError(false);
                      }
                    }}
                  />
                  {addMaterialPercentError && (
                    <p style={{ color: "red" }}>
                      من فضلك ادخل نسبة المادة الخام
                    </p>
                  )}
                </div>
              </div>
              <div className='add-material-modal-btns-div'>
                <button type='htmlSubmit' className='add-material-modal-addBtn'>
                  {t("add")}
                  {addLinkLoading && (
                    <CircularProgress
                      sx={{ color: "rgba(255, 97, 29, 1)", margin: "0px 10px" }}
                      size={18}
                    />
                  )}
                </button>
                <button
                  className='add-material-modal-cancelBtn'
                  onClick={() => {
                    setAddNewLinkOpen(false);
                    setProductPercent("");
                    setProductId("");
                    setMaterialPercent("");
                    setAddMaterialPercentError(false);
                    setAddProductError(false);
                    setAddProductPercentError(false);
                  }}
                >
                  {t("cancel")}
                </button>
              </div>
            </form>
          </div>
        </Box>
      </Modal>
      {/******delete link modal***** */}
      <Modal
        open={deleteLinkOpen}
        onClose={() => setDeleteLinkOpen(false)}
        aria-labelledby='modal-modal-title'
        aria-describedby='modal-modal-description'
        className='add-material-modal'
        style={{ direction: direction }}
      >
        <Box
          sx={{
            boxShadow: 24,
            p: 4,
          }}
        >
          <p
            style={{
              fontSize: "17px",
              fontWeight: "600",
              marginBottom: "10px",
              width: "100%",
              textAlign: "center",
            }}
          >
            {t("deleteConfirm")}{" "}
          </p>
          <div className='add-material-modal-body'>
            <div className='add-material-modal-btns-div'>
              <button
                onClick={deleteLinkedProductFunc}
                className='add-material-modal-addBtn'
              >
                {t("delete")}
                {deleteLinkLoading && (
                  <CircularProgress
                    sx={{ color: "rgba(255, 97, 29, 1)", margin: "0px 10px" }}
                    size={18}
                  />
                )}
              </button>
              <button
                className='add-material-modal-cancelBtn'
                onClick={() => {
                  setDeleteLinkOpen(false);
                }}
              >
                {t("cancel")}
              </button>
            </div>
          </div>
        </Box>
      </Modal>
      {/******edit link modal***** */}
      <Modal
        open={editLinkOpen}
        onClose={() => setEditLinkOpen(false)}
        aria-labelledby='modal-modal-title'
        aria-describedby='modal-modal-description'
        className='add-material-modal'
        style={{ direction: direction }}
      >
        <Box
          sx={{
            boxShadow: 24,
            p: 4,
          }}
        >
          <div className='add-material-modal-body'>
            <p
              style={{
                fontSize: "17px",
                fontWeight: "600",
                marginBottom: "10px",
              }}
            >
              ربط مكون جديد بهذه المادة
            </p>
            <div className='add-link-modal-fields-div'>
              <select
                className='add-link-modal-select'
                onChange={(e) => {
                  setProductId(e?.target?.value);
                }}
              >
                <option value='' disabled selected>
                  {editProductName}
                </option>
                {allProducts?.map((product) => (
                  <option value={product?.id}>{product?.name}</option>
                ))}
              </select>
            </div>

            <p
              style={{
                fontSize: "17px",
                fontWeight: "600",
                marginTop: "30px",
              }}
            >
              {t("ratio")}
            </p>
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                marginTop: "10px",
              }}
            >
              <div style={{ width: "45%" }}>
                <input
                  name='from'
                  type='number'
                  placeholder='نسبة المنتج'
                  className='add-link-inputs'
                  value={editProductPercent}
                  onChange={editLinkChange}
                />
              </div>
              <div style={{ width: "45%" }}>
                <input
                  name='to'
                  type='number'
                  placeholder='نسبة المادة الخام'
                  className='add-link-inputs'
                  value={editMaterialPercent}
                  onChange={editLinkChange}
                />
              </div>
            </div>
            <div className='add-material-modal-btns-div'>
              <button
                className='add-material-modal-addBtn'
                onClick={editLinkFunc}
              >
                {t("edit")}
              </button>
              <button
                className='add-material-modal-cancelBtn'
                onClick={() => {
                  setEditLinkOpen(false);
                  setProductPercent("");
                  setProductId("");
                  setMaterialPercent("");
                  setEditLinkObj({});
                  setEditLinkSentObj({});
                  setAddMaterialPercentError(false);
                  setAddProductError(false);
                  setAddProductPercentError(false);
                }}
              >
                {t("cancel")}
              </button>
            </div>
          </div>
        </Box>
      </Modal>
      {/******confirm edit link modal***** */}
      <Modal
        open={confirmEditLinkOpen}
        onClose={() => setConfirmEditLinkOpen(false)}
        aria-labelledby='modal-modal-title'
        aria-describedby='modal-modal-description'
        className='add-material-modal'
        style={{ direction: direction }}
      >
        <Box
          sx={{
            boxShadow: 24,
            p: 4,
          }}
        >
          <p
            style={{
              fontSize: "17px",
              fontWeight: "600",
              marginBottom: "10px",
              width: "100%",
              textAlign: "center",
            }}
          >
            تأكيد تعديل الربط
          </p>
          <div className='add-material-modal-body'>
            <div className='add-material-modal-btns-div'>
              <button
                onClick={confirmEditLinkFunc}
                className='add-material-modal-addBtn'
              >
                {t("ok")}
                {confirmEditLinkLoading && (
                  <CircularProgress
                    sx={{ color: "rgba(255, 97, 29, 1)", margin: "0px 10px" }}
                    size={18}
                  />
                )}
              </button>
              <button
                className='add-material-modal-cancelBtn'
                onClick={() => {
                  setConfirmEditLinkOpen(false);
                }}
              >
                {t("cancel")}
              </button>
            </div>
          </div>
        </Box>
      </Modal>
      {/* Modal  For Success & error Messages */}
      <Modal
        open={successModalOpen}
        aria-labelledby='modal-modal-title'
        aria-describedby='modal-modal-description'
        style={{ direction: direction }}
      >
        <div className='message-container'>
          <div className='message'>
            <div className='content'>
              {toggleSuccessError == "success" ? (
                <DoneIcon className='icon ' />
              ) : (
                <GppBadIcon style={{ color: "red", fontSize: "30px" }} />
              )}
              <p>
                {" "}
                {successModalIdentify === "add-material"
                  ? addMaterialMsg
                  : successModalIdentify === "add-link"
                  ? addLinkMsg
                  : successModalIdentify === "edit-link"
                  ? editLinkMsg
                  : successModalIdentify === "delete-link"
                  ? deleteLinkMsg
                  : successModalIdentify === "edit-store"
                  ? editMaterialMsg
                  : deleteStoreMsg}
              </p>
            </div>
            <button
              onClick={() => {
                setSuccessModalOpen(false);
              }}
            >
              حسناً
            </button>
          </div>
        </div>
      </Modal>
      {/******delete store modal***** */}
      <Modal
        open={deleteStoreOpen}
        onClose={() => setDeleteStoreOpen(false)}
        aria-labelledby='modal-modal-title'
        aria-describedby='modal-modal-description'
        className='add-material-modal'
        style={{ direction: direction }}
      >
        <Box
          sx={{
            boxShadow: 24,
            p: 4,
          }}
        >
          <p
            style={{
              fontSize: "17px",
              fontWeight: "600",
              marginBottom: "10px",
              width: "100%",
              textAlign: "center",
            }}
          >
            {t("deleteConfirm")}
          </p>
          <div className='add-material-modal-body'>
            <div className='add-material-modal-btns-div'>
              <button
                onClick={confirmDeleteStoreFunc}
                className='add-material-modal-addBtn'
              >
                {t("delete")}
                {deleteStoreLoading && (
                  <CircularProgress
                    sx={{ color: "rgba(255, 97, 29, 1)", margin: "0px 10px" }}
                    size={18}
                  />
                )}
              </button>
              <button
                className='add-material-modal-cancelBtn'
                onClick={() => {
                  setDeleteStoreOpen(false);
                }}
              >
                {t("cancel")}
              </button>
            </div>
          </div>
        </Box>
      </Modal>
      {/******edit store modal***** */}
      <Modal
        open={editStoreOpen}
        onClose={() => setEditStoreOpen(false)}
        aria-labelledby='modal-modal-title'
        aria-describedby='modal-modal-description'
        className='add-material-modal'
        style={{ direction: direction }}
      >
        <Box
          sx={{
            boxShadow: 24,
            p: 4,
          }}
        >
          <div className='add-material-modal-body'>
            <input
              type='text'
              placeholder='الاسم'
              name='name'
              className='add-material-inputs'
              style={{ padding: "12px 10px" }}
              value={editName}
              onChange={editStoreOnChange}
            />
            <input
              type='text'
              placeholder='الاسم بالانجليزية'
              name='nameEn'
              className='add-material-inputs'
              style={{ padding: "12px 10px" }}
              value={editNameEn}
              onChange={(e) => setEditNameEn(e.target.value)}
            />

            <div
              className='add-material-inputs'
              style={{
                display: "flex",
                justifyContent: "space-between",
                padding: "7px 10px",
              }}
            >
              <p
                style={{
                  color: "rgba(81, 81, 81, 1)",
                  fontSize: "14px",
                  fontWeight: "600",
                }}
              >
                {imageInput}
              </p>
              <input
                type='file'
                id='uploadImg'
                accept='image/*'
                style={{ display: "none" }}
                onChange={handleUploadEditedImage}
              />
              <label htmlFor='uploadImg' className='choose-img-btn'>
                {t("chooseFile")}
              </label>
            </div>

            <input
              type='number'
              name='quantity'
              placeholder='الكمية'
              className='add-material-inputs'
              style={{
                padding: "12px 10px",
                // opacity: editDisQuantity ? "0.5" : "1",
              }}
              value={editQuantity}
              onChange={editStoreOnChange}
              // disabled={editDisQuantity ? true : false}
            />
            <input
              type='number'
              name='disQuantity'
              placeholder='تقليل الكمية'
              className='add-material-inputs'
              style={{
                padding: "12px 10px",
                // opacity: editQuantity ? "0.5" : "1",
              }}
              value={editDisQuantity}
              onChange={editStoreOnChange}
              // disabled={editQuantity ? true : false}
            />
            {quantityError && (
              <p style={{ color: "red" }}>
                لا يمكن ادخال الكمية وتقليل الكمية معا . يجب ادخال قيمة واحدة
                فقط
              </p>
            )}
            <input
              type='text'
              name='measruingUnit'
              placeholder='وحدة القياس'
              className='add-material-inputs'
              style={{ padding: "12px 10px" }}
              value={editUnit}
              onChange={editStoreOnChange}
            />
            <input
              type='text'
              name='measruingUnitEn'
              placeholder='وحدة القياس بالانجليزية'
              className='add-material-inputs'
              style={{ padding: "12px 10px" }}
              value={editUnitEn}
              onChange={(e) => setEditUnitEn(e.target.value)}
            />

            <div className='add-material-modal-btns-div'>
              <button
                className='add-material-modal-addBtn'
                onClick={editStoreFunc}
              >
                {t("edit")}
              </button>
              <button
                className='add-material-modal-cancelBtn'
                onClick={() => {
                  setEditStoreOpen(false);
                  setEditImage(null);
                  setEditDisQuantity("");
                  setEditStoreObj({});
                  setEditStoreSentObj({});
                }}
              >
                {t("cancel")}
              </button>
            </div>
          </div>
        </Box>
      </Modal>
      {/******confirm edit store modal***** */}
      <Modal
        open={confirmEditStoreOpen}
        onClose={() => confirmEditStoreOpen(false)}
        aria-labelledby='modal-modal-title'
        aria-describedby='modal-modal-description'
        className='add-material-modal'
        style={{ direction: direction }}
      >
        <Box
          sx={{
            boxShadow: 24,
            p: 4,
          }}
        >
          <p
            style={{
              fontSize: "17px",
              fontWeight: "600",
              marginBottom: "10px",
              width: "100%",
              textAlign: "center",
            }}
          >
            تأكيد تعديل المنتج
          </p>
          <div className='add-material-modal-body'>
            <div className='add-material-modal-btns-div'>
              <button
                onClick={confirmEditStoreFunc}
                className='add-material-modal-addBtn'
              >
                {t("ok")}
                {editStoreLoading && (
                  <CircularProgress
                    sx={{ color: "rgba(255, 97, 29, 1)", margin: "0px 10px" }}
                    size={18}
                  />
                )}
              </button>
              <button
                className='add-material-modal-cancelBtn'
                onClick={() => {
                  setConfirmEditStoreOpen(false);
                }}
              >
                {t("cancel")}
              </button>
            </div>
          </div>
        </Box>
      </Modal>
    </>
  );
};
