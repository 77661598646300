import "./ProductList.scss";
import React, { useContext, useEffect, useState } from "react";
import { DataGrid } from "@mui/x-data-grid";
import { Link } from "react-router-dom";
import { CircularProgress } from "@mui/material";
import { useMediaQuery } from "@mui/material";
import { useTranslation } from "react-i18next";
import { LanguageContext } from "../../context/LanguageContext";
import Modal from "@mui/material/Modal";
import DoneIcon from "@mui/icons-material/CheckCircleOutlined";
import ErrorIcon from "@mui/icons-material/ErrorOutlineOutlined";
import Select from "react-select";
import newRequest from "../../utils/newRequest";

const ProductList = () => {
  const isMobile = useMediaQuery("(max-width:767px)");
  const isTablet = useMediaQuery("(min-width:768px) and (max-width:1023px)");
  const [openAddProduct, setOpenAddProduct] = useState(false);
  const [openEditProduct, setOpenEditProduct] = useState(false);
  const [done, setDone] = useState(false);
  const [error, setError] = useState(false);
  const [loading, setLoading] = useState(false);
  const [confirmDeleteMessage, setConfirmDeleteMessage] = useState(false);
  const [products, setProducts] = useState([]);
  const [t] = useTranslation();
  const { direction } = useContext(LanguageContext);
  const [name, setName] = useState(null);
  const [englishName, setEnglishName] = useState("");
  const [arabicName, setArabicName] = useState("");
  const [desc, setDesc] = useState(null);
  const [calories, setCalories] = useState(null);
  const [image, setImage] = useState(null);
  const [price, setPrice] = useState(null);
  const [catogryId, setCatogryId] = useState(null);
  const [categories, setCategories] = useState([]);
  const [selectedCategory, setSelectedCategory] = useState(null);
  const [extra, setExtra] = useState([]);
  const [selectedOptions, setSelectedOptions] = useState([]);
  const [deletedProduct, setDeletedProduct] = useState(null);
  const [editProduct, setEditProduct] = useState(null);
  const [selectedOptionsForEdit, setSelectedOptionsForEdit] = useState([]);

  useEffect(() => {
    const Fetching = async () => {
      try {
        setLoading(true);

        const catogryRes = await newRequest.get("/catogry");
        setCategories(catogryRes?.data?.data);
        const productsRes = await newRequest.get("/product");
        setProducts(productsRes?.data?.data);
        const extraRes = await newRequest.get(
          "/catogry/65ff30670d16ea01b7d57739/products"
        );
        setExtra(extraRes?.data?.data);
        setLoading(false);
      } catch (error) {
        console.log(error);
        setLoading(false);
      }
    };
    Fetching();
  }, [done]);

  const handleSubmit = async (e) => {
    e.preventDefault();
    const extraIds = selectedOptions.map((item) => {
      return item.value;
    });

    const formData = new FormData();
    if (englishName) {
      formData.append("name", name + `-${englishName}`);
    } else {
      formData.append("name", name);
    }
    formData.append("image", image);
    formData.append("desc", desc);
    formData.append("calories", calories);
    formData.append("price", price);
    formData.append("catogryId", catogryId.value);
    extraIds.forEach((id) => {
      formData.append("extra[]", id);
    });

    try {
      setLoading(true);
      await newRequest.post("/product", formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      });
      setDone(true);
      setSelectedOptions([]);
      setCatogryId(null);
      setLoading(false);
    } catch (error) {
      console.log(error);
      setLoading(false);
      setError(true);
    }
  };

  const handleEdit = async (e) => {
    e.preventDefault();
    try {
      e.preventDefault();
      const extraIds = selectedOptionsForEdit.map((item) => {
        return item.value;
      });

      setLoading(true);
      const formData = new FormData();

      if (arabicName || englishName)
        formData.append("name", arabicName + `-${englishName}`);
      if (desc) formData.append("desc", desc);
      if (calories) formData.append("calories", calories);
      if (price) formData.append("price", price);
      if (selectedCategory)
        formData.append("catogryId", selectedCategory.value);
      if (image) formData.append("image", image);
      if (extraIds.length === 0) {
        formData.append("extra", []);
      } else {
        extraIds.forEach((_id) => {
          formData.append("extra[]", _id);
        });
      }

      await newRequest.put(`/product/${editProduct._id}`, formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      });

      setName(null);
      setDesc(null);
      setCalories(null);
      setPrice(null);
      setSelectedCategory(null);
      setImage(null);
      setDone(true);
      setLoading(false);
    } catch (error) {
      console.log(error);
      setLoading(false);
    }
  };

  const handleDelete = async () => {
    try {
      setLoading(true);
      await newRequest.delete(`/product/${deletedProduct._id}`);
      setProducts(products.filter((item) => item._id !== deletedProduct._id));
      setConfirmDeleteMessage(false);
      setDeletedProduct(null);
      setLoading(false);
    } catch (error) {
      console.log(error);
      setLoading(false);
    }
  };

  const handleChange = (selectedOptions) => {
    setSelectedOptions(selectedOptions);
  };

  const handleChangeEdit = (selectedOptionsForEdit) => {
    setSelectedOptionsForEdit(selectedOptionsForEdit);
  };

  const handleCategoryEdit = (selectedCategory) => {
    console.log(selectedCategory);
    setSelectedCategory(selectedCategory);
  };

  const handleCategoryChange = (catogryId) => {
    setCatogryId(catogryId);
    console.log(catogryId.value);
  };

  const options = extra?.map((item) => {
    return { value: item._id, label: item.name };
  });

  const optionsCategories = categories?.map((item) => {
    return { value: item.id, label: item.name };
  });

  const productColumns = [
    {
      field: "name",
      headerName: t("name"),
      width: isMobile ? 120 : isTablet ? 160 : 200,
      headerAlign: "center",
      align: "center",
    },
    {
      field: "price",
      headerName: t("price"),
      width: isMobile ? 120 : isTablet ? 160 : 200,
      headerAlign: "center",
      align: "center",
    },
    {
      field: "desc",
      headerName: t("description"),
      width: isMobile ? 120 : isTablet ? 160 : 200,
      headerAlign: "center",
      align: "center",
    },
    {
      field: "catogryId",
      headerName: t("section"),
      width: isMobile ? 120 : isTablet ? 160 : 200,
      headerAlign: "center",
      align: "center",
      valueGetter: (params) => params.row.catogryId?.name ?? "",
    },
    {
      field: "image",
      headerName: t("photo"),
      headerAlign: "center",
      align: "center",
      width: isMobile ? 120 : isTablet ? 160 : 200,
      renderCell: (params) => {
        return (
          <div className='cellWithImg'>
            <img
              className='cellImg'
              src={`${process.env.REACT_APP_ASSETS_BASE_URL}${params.row.image}`}
              alt='avatar'
            />
          </div>
        );
      },
    },
  ];

  const actionColumn = [
    {
      field: "action",
      headerName: t("action"),
      width: isMobile ? 120 : isTablet ? 160 : 200,
      align: "center",
      renderCell: (params) => {
        return (
          <div className='cellAction'>
            <Link
              onClick={() => {
                setEditProduct(params.row);
                setArabicName(params?.row?.name?.split("-")[0]);
                setEnglishName(params?.row?.name?.split("-")[1]);
                setOpenEditProduct(true);
                setSelectedOptionsForEdit(
                  params?.row?.extra.map((item) => {
                    return { value: item._id, label: item.name };
                  })
                );
              }}
              className='edit-button'
            >
              <div className='viewButton'>{t("edit")}</div>
            </Link>
            <div
              className='delete-button'
              onClick={() => {
                setDeletedProduct(params.row);
                setConfirmDeleteMessage(true);
              }}
            >
              {t("delete")}
            </div>
          </div>
        );
      },
    },
  ];

  return (
    <>
      <div className='test'>
        <div className='datatableTitle'>
          <Link onClick={() => setOpenAddProduct(true)} className='add-button'>
            {t("addProduct")}
          </Link>
        </div>

        <DataGrid
          className='datagrid'
          rows={products}
          params={products}
          columns={productColumns.concat(actionColumn)}
          pageSize={9}
          rowsPerPageOptions={[9]}
          sx={{ height: 580 }}
          getRowId={(row) => row._id}
          disableVirtualization
        />
      </div>

      {/* For Open Add New Product */}
      <Modal
        open={openAddProduct}
        aria-labelledby='modal-modal-title'
        aria-describedby='modal-modal-description'
        style={{ direction: direction }}
      >
        <div className='form-container'>
          {/* Add Product Form */}
          <form action='' onSubmit={handleSubmit}>
            <div className='control-group'>
              <div className='form-control'>
                <input
                  type='text'
                  placeholder={t("name")}
                  required
                  onChange={(e) => setName(e.target.value)}
                />
              </div>
              <div className='form-control'>
                <input
                  type='text'
                  pattern='[0-9]*'
                  required
                  placeholder={t("calaories")}
                  onChange={(e) => setCalories(e.target.value)}
                />
              </div>
            </div>

            <div className='control-group'>
              <div className='form-control'>
                <input
                  type='text'
                  placeholder={t("englishName")}
                  onChange={(e) => setEnglishName(e.target.value)}
                />
              </div>
            </div>

            <div className='control-group'>
              <div className='form-control'>
                <input
                  type='file'
                  accept='image/*'
                  placeholder={t("photo")}
                  required
                  onChange={(e) => setImage(e.target.files[0])}
                />
              </div>

              <div className='form-control'>
                <input
                  type='text'
                  pattern='[0-9]*'
                  placeholder={t("price")}
                  required
                  onChange={(e) => setPrice(e.target.value)}
                />
              </div>
            </div>

            <div className='control-group' style={{ position: "relative" }}>
              <Select
                options={optionsCategories}
                className='react-selector'
                placeholder={t("section")}
                value={catogryId}
                onChange={handleCategoryChange}
              />
            </div>

            {catogryId?.value !== "65ff30670d16ea01b7d57739" && (
              <div className='control-group' style={{ position: "relative" }}>
                <Select
                  options={options}
                  className='react-selector'
                  placeholder={t("additions")}
                  value={selectedOptions}
                  onChange={handleChange}
                  isMulti={true}
                />
              </div>
            )}

            <div className='control-group'>
              <textarea
                name=''
                id=''
                placeholder={t("description")}
                required
                title='برجاء قم باضافة وصف لا يقل عن 20 حرف'
                onChange={(e) => setDesc(e.target.value)}
              />
            </div>

            <div className='form-action'>
              <button type='submit' className='action-btn'>
                {t("add")}
              </button>
              <button
                type='submit'
                className='action-btn-cancel'
                onClick={() => {
                  setOpenAddProduct(false);
                  setSelectedOptions([]);
                  setCatogryId(null);
                }}
              >
                {t("cancel")}
              </button>
            </div>
          </form>

          {/* Modal  For Success Message */}
          <Modal
            open={done}
            aria-labelledby='modal-modal-title'
            aria-describedby='modal-modal-description'
            style={{ direction: direction }}
          >
            <div className='message-container'>
              <div className='message'>
                <div className='content'>
                  <DoneIcon className='icon ' />
                  <p>{t("success")}</p>
                </div>
                <button
                  onClick={() => {
                    setOpenAddProduct(false);
                    setDone(false);
                  }}
                >
                  {t("ok")}
                </button>
              </div>
            </div>
          </Modal>

          {/* Modal For Error Message */}
          <Modal
            open={error}
            aria-labelledby='modal-modal-title'
            aria-describedby='modal-modal-description'
            style={{ direction: direction }}
          >
            <div className='message-container'>
              <div className='message'>
                <div className='content'>
                  <ErrorIcon className='icon ' />
                  <p>{t("serverError")}</p>
                </div>
                <button
                  onClick={() => {
                    setError(false);
                    // window.location.reload();
                  }}
                >
                  {t("ok")}
                </button>
              </div>
            </div>
          </Modal>
        </div>
      </Modal>

      {/* For Open Edit New Product */}
      <Modal
        open={openEditProduct}
        aria-labelledby='modal-modal-title'
        aria-describedby='modal-modal-description'
        style={{ direction: direction }}
      >
        <div className='form-container'>
          {/* Edit Product Form */}
          <form action='' onSubmit={handleEdit}>
            <div className='control-group'>
              <div className='form-control'>
                <label htmlFor=''>{t("name")}</label>
                <input
                  type='text'
                  placeholder={arabicName || ""}
                  onChange={(e) => setArabicName(e.target.value)}
                />
              </div>

              <div className='form-control'>
                <label htmlFor=''> {t("calaories")}</label>
                <input
                  type='text'
                  pattern='[0-9]*'
                  placeholder={editProduct?.calories}
                  onChange={(e) => setCalories(e.target.value)}
                />
              </div>
            </div>

            <div className='control-group'>
              <div className='form-control'>
                <label htmlFor=''>{t("englishName")}</label>
                <input
                  type='text'
                  placeholder={englishName || ""}
                  onChange={(e) => setEnglishName(e.target.value)}
                />
              </div>
            </div>

            <div className='control-group'>
              <div className='form-control'>
                <label htmlFor=''>{t("photo")}</label>
                <input
                  type='file'
                  accept='image/*'
                  placeholder={t("photo")}
                  onChange={(e) => setImage(e.target.files[0])}
                />
              </div>

              <div className='form-control'>
                <label htmlFor=''>{t("price")}</label>
                <input
                  type='text'
                  pattern='[0-9]*'
                  placeholder={editProduct?.price}
                  onChange={(e) => setPrice(e.target.value)}
                />
              </div>
            </div>

            <div className='control-group' style={{ position: "relative" }}>
              <Select
                options={optionsCategories}
                className='react-selector'
                placeholder={t("section")}
                value={selectedCategory}
                onChange={handleCategoryEdit}
              />
            </div>

            <div className='control-group' style={{ position: "relative" }}>
              <Select
                options={options}
                className='react-selector'
                placeholder={t("additions")}
                value={selectedOptionsForEdit}
                onChange={handleChangeEdit}
                isMulti={true}
              />
            </div>

            <div className='control-group'>
              <label htmlFor=''>{t("description")}</label>
              <textarea
                name=''
                id=''
                placeholder={editProduct?.desc}
                title='برجاء قم باضافة وصف لا يقل عن 20 حرف'
                onChange={(e) => setDesc(e.target.value)}
              />
            </div>

            <div className='form-action'>
              <button type='submit' className='action-btn'>
                {t("edit")}
              </button>
              <button
                type='submit'
                className='action-btn-cancel'
                onClick={() => {
                  setOpenEditProduct(false);
                  setSelectedOptionsForEdit([]);
                  setSelectedCategory(null);
                }}
              >
                {t("cancel")}
              </button>
            </div>
          </form>

          {/* Modal  For Success Message */}
          <Modal
            open={done}
            aria-labelledby='modal-modal-title'
            aria-describedby='modal-modal-description'
            style={{ direction: direction }}
          >
            <div className='message-container'>
              <div className='message'>
                <div className='content'>
                  <DoneIcon className='icon ' />
                  <p>{t("editDone")}</p>
                </div>
                <button
                  onClick={() => {
                    setOpenEditProduct(false);
                    setDone(false);
                    // window.location.reload();
                  }}
                >
                  {t("ok")}
                </button>
              </div>
            </div>
          </Modal>

          {/* Modal For Error Message */}
          <Modal
            open={error}
            aria-labelledby='modal-modal-title'
            aria-describedby='modal-modal-description'
            style={{ direction: direction }}
          >
            <div className='message-container'>
              <div className='message'>
                <div className='content'>
                  <ErrorIcon className='icon ' />
                  <p>{t("serverError")}</p>
                </div>
                <button
                  onClick={() => {
                    setError(false);
                  }}
                >
                  {t("ok")}
                </button>
              </div>
            </div>
          </Modal>
        </div>
      </Modal>

      {/* Loading Modal */}
      <Modal
        open={loading}
        aria-labelledby='modal-modal-title'
        aria-describedby='modal-modal-description'
        style={{ direction: direction }}
      >
        <div className='progress'>
          <CircularProgress width={40} />
        </div>
      </Modal>

      {/* Modal For Delete Message */}
      <Modal
        open={confirmDeleteMessage}
        aria-labelledby='modal-modal-title'
        aria-describedby='modal-modal-description'
        style={{ direction: direction }}
      >
        <div className='message-container'>
          <div className='message'>
            <div className='content'>
              <p>{t("deleteConfirm")}</p>
            </div>
            <div className='action-container'>
              <button
                className='action-btn'
                onClick={() => {
                  handleDelete();
                }}
              >
                {t("ok")}
              </button>
              <button
                className='action-btn-cancel'
                onClick={() => {
                  setConfirmDeleteMessage(false);
                }}
              >
                لا
              </button>
            </div>
          </div>
        </div>
      </Modal>
    </>
  );
};

export default ProductList;
