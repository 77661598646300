import { useState } from "react";
import "./Settings.scss";
import { useEffect } from "react";
import newRequest from "../../utils/newRequest";
import { CircularProgress, Modal } from "@mui/material";
import CloseIcon from "@mui/icons-material/CloseOutlined";
import { useTranslation } from "react-i18next";

const Settings = () => {
  const [loading, setLoading] = useState(false);
  const [shouldRefetch, setShouldRefetch] = useState(false);
  const [resturant, setResturant] = useState(null);
  const [englishName, setEnglishName] = useState("");
  const [arabicName, setArabicName] = useState("");
  const [shippingPrice, setShippingPrice] = useState(null);
  const [imageProfile, setImageProfile] = useState(null);
  const [profileCover, setProfileCover] = useState(null);
  const [englishCurrency, setEnglishCurrency] = useState("");
  const [arabicCurrency, setArabicCurrency] = useState("");
  const [location, setLocation] = useState(null);
  const [tabels, setTabels] = useState(null);
  const [icon, setIcon] = useState(null);
  const [link, setLink] = useState(null);
  const [cheifAlert, setCheifAlert] = useState(null);
  const [waiterAlert, setWaiterAlert] = useState(null);
  const [taxNumber, setTaxNumber] = useState(null);
  const [commercialRecord, setCommercialRecord] = useState(null);
  const [englishBranchName, setEnglishBranchName] = useState("");
  const [arabicBranchName, setArabicBranchName] = useState("");
  const [orgnizationName, setOrgnizationName] = useState(null);
  const [englishOrgnizationName, setEnglishOrgnizationName] = useState("");
  const [arabicOrgnizationName, setArabicOrgnizationName] = useState("");
  const [qrCodeLink, setQrCodeLink] = useState(null);
  const [addLink, setAddLink] = useState(false);
  const [t] = useTranslation();
  const Lang = localStorage.getItem("i18nextLng");

  useEffect(() => {
    const getResturant = async () => {
      setLoading(true);
      try {
        const res = await newRequest.get("/profile");
        const tabelsRes = await newRequest.get(`/help/tables`);
        setTabels(tabelsRes?.data?.data?.length);
        setResturant(res?.data?.data[0]);
        setArabicName(res?.data?.data[0]?.name?.split("-")[0]);
        setEnglishName(res?.data?.data[0]?.name?.split("-")[1]);
        setArabicCurrency(res?.data?.data[0]?.currency?.split(" ")[0]);
        setEnglishCurrency(res?.data?.data[0]?.currency?.split(" ")[1]);
        setArabicBranchName(res?.data?.data[0]?.branchName?.split("-")[0]);
        setEnglishBranchName(res?.data?.data[0]?.branchName?.split("-")[1]);
        setArabicOrgnizationName(
          res?.data?.data[0]?.orgnizationName?.split("-")[0]
        );
        setEnglishOrgnizationName(
          res?.data?.data[0]?.orgnizationName?.split("-")[1]
        );

        setLoading(false);
      } catch (error) {
        console.log(error);
        setLoading(false);
      }
    };

    getResturant();
  }, [shouldRefetch]);

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    try {
      const formData = new FormData();
      if (englishName) {
        formData.append("name", arabicName + `-${englishName}`);
      } else {
        formData.append("name", arabicName);
      }

      if (englishCurrency) {
        formData.append("currency", arabicCurrency + ` ${englishCurrency}`);
      } else {
        formData.append("currency", arabicCurrency);
      }

      if (englishBranchName) {
        formData.append(
          "branchName",
          arabicBranchName + `-${englishBranchName}`
        );
      } else {
        formData.append("branchName", arabicBranchName);
      }

      if (englishOrgnizationName) {
        formData.append(
          "orgnizationName",
          arabicOrgnizationName + `-${englishOrgnizationName}`
        );
      } else {
        formData.append("orgnizationName", arabicOrgnizationName);
      }

      if (shippingPrice) formData.append("shippingPrice", shippingPrice);
      if (imageProfile) formData.append("imageProfile", imageProfile);
      if (profileCover) formData.append("profileCover", profileCover);
      if (location) formData.append("location", location);
      if (cheifAlert) formData.append("cheifAlert", cheifAlert);
      if (waiterAlert) formData.append("waiterAlert", waiterAlert);
      if (taxNumber) formData.append("TaxNumber", taxNumber);
      if (commercialRecord)
        formData.append("CommercialRecord", commercialRecord);
      if (orgnizationName) formData.append("orgnizationName", orgnizationName);
      if (qrCodeLink) formData.append("qrCodeLink", qrCodeLink);
      const res = await newRequest.put(`/profile/${resturant?.id}`, formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      });

      setResturant(res?.data?.data);
      setShippingPrice(null);
      setImageProfile(null);
      setProfileCover(null);
      setCheifAlert(null);
      setWaiterAlert(null);
      setLoading(false);
      setTaxNumber(null);
      setCommercialRecord(null);
      setOrgnizationName(null);
      setQrCodeLink(null);
      window.location.reload();
    } catch (error) {
      console.log(error);
      setLoading(false);
    }
  };

  const handleTabels = async (e) => {
    e.preventDefault();
    try {
      setLoading(true);

      await newRequest.post(`/help/tables`, { numberOfTable: tabels });
      setLoading(false);
      setShouldRefetch(true);
    } catch (error) {
      console.log(error);
      setLoading(false);
    }
  };

  const handleSocial = async (e) => {
    e.preventDefault();
    if (!link && !icon) {
      return;
    }
    setLoading(true);
    const formData = new FormData();
    if (icon) formData.append("icon", icon);
    if (link) formData.append("link", link);
    try {
      const res = await newRequest.post(
        `/profile/social/${resturant.id}`,
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      );

      setResturant(res?.data?.data);
      setLoading(false);
      setIcon(null);
      setLink(null);
      setAddLink(false);
    } catch (error) {
      setLoading(false);
      console.error("Error:", error);
    }
  };

  const handleRemove = async (id) => {
    try {
      setLoading(true);
      await newRequest.delete(`/profile/${resturant.id}/social/${id}`);
      const res = await newRequest.get("/profile");
      setResturant(res?.data?.data[0]);
      setLoading(false);
    } catch (error) {
      console.error("Error:", error);
      setLoading(false);
    }
  };

  return (
    <>
      <div className='admin-container'>
        <div className='admin-profile'>
          <div className='header-container'>
            <div className='image-container'>
              <img
                src={`${process.env.REACT_APP_ASSETS_BASE_URL}${resturant?.profileCover}`}
                alt=''
              />
            </div>

            <div className='admin-header'>
              <div className='admin-nav'>
                <div className='wrapper'>
                  <img
                    src={`${process.env.REACT_APP_ASSETS_BASE_URL}${resturant?.imageProfile}`}
                    alt=''
                  />
                  <h3 className='name'>{resturant?.name}</h3>
                </div>

                <div className='wrapper'>
                  <h3>{`${t("tabels")} : ${tabels || ""}`} </h3>
                </div>

                <div className='wrapper'>
                  <h3>{`${t("tax")} % ${resturant?.shippingPrice}`} </h3>
                </div>
              </div>
            </div>
          </div>

          <div className='info-container'>
            <form onSubmit={handleSubmit}>
              <h2>{t("mainInfo")}</h2>

              <div className='control-group'>
                <div className='form-control'>
                  <label
                    htmlFor='first-name'
                    style={{
                      right: Lang === "ar" && "10px",
                      left: Lang === "en" && "10px",
                    }}
                  >
                    {t("name")}
                  </label>
                  <input
                    type='text'
                    placeholder={arabicName}
                    onChange={(e) => setArabicName(e.target.value)}
                  />
                </div>

                <div className='form-control'>
                  <label
                    htmlFor='first-name'
                    style={{
                      right: Lang === "ar" && "10px",
                      left: Lang === "en" && "10px",
                    }}
                  >
                    {t("englishName")}
                  </label>
                  <input
                    type='text'
                    placeholder={englishName}
                    onChange={(e) => setEnglishName(e.target.value)}
                  />
                </div>
              </div>

              <div className='control-group'>
                <div className='form-control image' style={{ width: "50%" }}>
                  <label
                    htmlFor='first-name'
                    style={{
                      right: Lang === "ar" && "10px",
                      left: Lang === "en" && "10px",
                    }}
                  >
                    {t("logo")}
                  </label>
                  <input
                    type='file'
                    accept='image/*'
                    onChange={(e) => setImageProfile(e.target.files[0])}
                  />
                </div>

                <div className='form-control image' style={{ width: "50%" }}>
                  <label
                    htmlFor='first-name'
                    style={{
                      right: Lang === "ar" && "10px",
                      left: Lang === "en" && "10px",
                    }}
                  >
                    {t("cover")}
                  </label>
                  <input
                    type='file'
                    accept='image/*'
                    onChange={(e) => setProfileCover(e.target.files[0])}
                  />
                </div>
              </div>

              <div className='control-group'>
                <div className='form-control'>
                  <label
                    htmlFor='first-name'
                    style={{
                      right: Lang === "ar" && "10px",
                      left: Lang === "en" && "10px",
                    }}
                  >
                    {t("currency")}
                  </label>
                  <input
                    type='text'
                    placeholder={arabicCurrency}
                    pattern='^[\u0621-\u064A\u0660-\u0669\u0671-\u06D3a-zA-Z\s]+$'
                    onChange={(e) => setArabicCurrency(e.target.value)}
                  />
                </div>

                <div className='form-control'>
                  <label
                    htmlFor='first-name'
                    style={{
                      right: Lang === "ar" && "10px",
                      left: Lang === "en" && "10px",
                    }}
                  >
                    {t("currencyEnglish")}
                  </label>
                  <input
                    placeholder={englishCurrency}
                    type='text'
                    onChange={(e) => setEnglishCurrency(e.target.value)}
                  />
                </div>
              </div>

              <div className='control-group'>
                <div className='form-control'>
                  <label
                    htmlFor='first-name'
                    style={{
                      right: Lang === "ar" && "10px",
                      left: Lang === "en" && "10px",
                    }}
                  >
                    {t("noticeOfHelpDelay")}
                  </label>
                  <input
                    type='text'
                    placeholder={
                      resturant?.waiterAlert
                        ? resturant?.waiterAlert + t("minute")
                        : ""
                    }
                    pattern='[0-9]*'
                    onChange={(e) => setWaiterAlert(e.target.value)}
                  />
                </div>
                <div className='form-control'>
                  <label
                    htmlFor='first-name'
                    style={{
                      right: Lang === "ar" && "10px",
                      left: Lang === "en" && "10px",
                    }}
                  >
                    {t("noticeOfOrderDelay")}
                  </label>
                  <input
                    type='text'
                    placeholder={
                      resturant?.cheifAlert
                        ? resturant?.cheifAlert + t("minute")
                        : ""
                    }
                    pattern='[0-9]*'
                    onChange={(e) => setCheifAlert(e.target.value)}
                  />
                </div>
              </div>

              <div className='control-group'>
                <div className='form-control'>
                  <label
                    htmlFor='first-name'
                    style={{
                      right: Lang === "ar" && "10px",
                      left: Lang === "en" && "10px",
                    }}
                  >
                    {t("taxNumber")}
                  </label>
                  <input
                    type='text'
                    placeholder={resturant?.TaxNumber}
                    onChange={(e) => setTaxNumber(e.target.value)}
                  />
                </div>
                <div className='form-control'>
                  <label
                    htmlFor='first-name'
                    style={{
                      right: Lang === "ar" && "10px",
                      left: Lang === "en" && "10px",
                    }}
                  >
                    {t("commercialRecord")}
                  </label>
                  <input
                    type='text'
                    placeholder={resturant?.CommercialRecord}
                    onChange={(e) => setCommercialRecord(e.target.value)}
                  />
                </div>
              </div>

              <div className='control-group'>
                <div className='form-control'>
                  <label
                    htmlFor='first-name'
                    style={{
                      right: Lang === "ar" && "10px",
                      left: Lang === "en" && "10px",
                    }}
                  >
                    {t("branchName")}
                  </label>
                  <input
                    type='text'
                    placeholder={arabicBranchName}
                    onChange={(e) => setArabicBranchName(e.target.value)}
                  />
                </div>

                <div className='form-control'>
                  <label
                    htmlFor='first-name'
                    style={{
                      right: Lang === "ar" && "10px",
                      left: Lang === "en" && "10px",
                    }}
                  >
                    {t("branchNameEnglish")}
                  </label>
                  <input
                    type='text'
                    placeholder={englishBranchName}
                    onChange={(e) => setEnglishBranchName(e.target.value)}
                  />
                </div>
              </div>

              <div className='control-group'>
                <div className='form-control'>
                  <label
                    htmlFor='first-name'
                    style={{
                      right: Lang === "ar" && "10px",
                      left: Lang === "en" && "10px",
                    }}
                  >
                    {t("orgnizationName")}
                  </label>
                  <input
                    type='text'
                    placeholder={arabicOrgnizationName}
                    onChange={(e) => setArabicOrgnizationName(e.target.value)}
                  />
                </div>

                <div className='form-control'>
                  <label
                    htmlFor='first-name'
                    style={{
                      right: Lang === "ar" && "10px",
                      left: Lang === "en" && "10px",
                    }}
                  >
                    {t("orgnizationNameEnglish")}
                  </label>
                  <input
                    type='text'
                    placeholder={englishOrgnizationName}
                    onChange={(e) => setEnglishOrgnizationName(e.target.value)}
                  />
                </div>
              </div>

              <div className='control-group'>
                <div className='form-control'>
                  <label
                    htmlFor='last-name'
                    style={{
                      right: Lang === "ar" && "10px",
                      left: Lang === "en" && "10px",
                    }}
                  >
                    {t("tax")}
                  </label>
                  <input
                    type='text'
                    placeholder={resturant?.shippingPrice}
                    pattern='[0-9]*'
                    onChange={(e) => setShippingPrice(e.target.value)}
                  />
                </div>

                <div className='form-control'>
                  <label
                    htmlFor='first-name'
                    style={{
                      right: Lang === "ar" && "10px",
                      left: Lang === "en" && "10px",
                    }}
                  >
                    {t("googleMap")}
                  </label>
                  <input
                    type='text'
                    placeholder={resturant?.location}
                    onChange={(e) => setLocation(e.target.value)}
                  />
                </div>
              </div>

              <div className='control-group'>
                <div className='form-control'>
                  <label
                    htmlFor='first-name'
                    style={{
                      right: Lang === "ar" && "10px",
                      left: Lang === "en" && "10px",
                    }}
                  >
                    {t("qrCodeLink")}
                  </label>
                  <input
                    type='text'
                    placeholder={resturant?.qrCodeLink}
                    onChange={(e) => setQrCodeLink(e.target.value)}
                  />
                </div>
              </div>

              <div className='control-group' style={{ gap: "16px" }}>
                <div className='form-action'>
                  <button type='submit'>{t("save")}</button>
                </div>
              </div>
            </form>

            <form onSubmit={handleTabels}>
              <h2>{t("tabels")}</h2>

              <div className='control-group'>
                <div className='form-control'>
                  <label
                    htmlFor='first-name'
                    style={{
                      right: Lang === "ar" && "10px",
                      left: Lang === "en" && "10px",
                    }}
                  >
                    {t("tabels")}
                  </label>
                  <input
                    type='text'
                    required
                    pattern='[1-9][0-9]*'
                    placeholder={tabels || ""}
                    onChange={(e) => setTabels(e.target.value)}
                  />
                </div>
              </div>

              <div className='control-group'>
                <div className='form-action'>
                  <button type='submit'>{t("save")}</button>
                </div>
              </div>
            </form>

            <form onSubmit={handleSocial}>
              <h2>{t("social")}</h2>
              {resturant?.socialMediaLinks?.map((item, index) => (
                <div
                  className='control-group'
                  key={index}
                  style={{ flexDirection: "row" }}
                >
                  <div className='icon-container'>
                    <img
                      src={`${process.env.REACT_APP_ASSETS_BASE_URL}${item?.icon}`}
                      alt=''
                    />
                  </div>
                  <div className='form-control'>
                    <label
                      htmlFor='first-name'
                      style={{
                        right: Lang === "ar" && "10px",
                        left: Lang === "en" && "10px",
                      }}
                    >
                      Link
                    </label>
                    <input type='text' placeholder={item?.link} />
                  </div>
                  <div
                    className='remove'
                    title='Remove link'
                    onClick={() => handleRemove(item._id)}
                  >
                    <CloseIcon />
                  </div>
                </div>
              ))}

              {addLink && (
                <div className='control-group'>
                  <div className='form-control'>
                    <label
                      style={{
                        right: Lang === "ar" && "10px",
                        left: Lang === "en" && "10px",
                      }}
                    >
                      Link
                    </label>
                    <input
                      type='text'
                      required
                      onChange={(e) => setLink(e.target.value)}
                    />
                  </div>
                  <div className='form-control'>
                    <label
                      style={{
                        right: Lang === "ar" && "10px",
                        left: Lang === "en" && "10px",
                      }}
                    >
                      Icon
                    </label>
                    <input
                      type='file'
                      accept='image/*'
                      required
                      onChange={(e) => setIcon(e.target.files[0])}
                    />
                  </div>

                  <div className='form-action'>
                    <button
                      type='submit'
                      onClick={() => {
                        setAddLink(false);
                        setIcon(null);
                        setLink(null);
                      }}
                    >
                      {t("delete")}
                    </button>
                  </div>
                </div>
              )}

              <div className='form-actions'>
                <div className='form-action'>
                  <button type='submit'> {t("save")}</button>
                </div>
                <div className='form-action'>
                  <button type='button' onClick={() => setAddLink(true)}>
                    {t("add")}
                  </button>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>

      {/* Modal For Loading */}
      <Modal
        open={loading}
        aria-labelledby='modal-modal-title'
        aria-describedby='modal-modal-description'
      >
        <div className='progress'>
          <CircularProgress width={40} sx={{ color: "#FF611D" }} />
        </div>
      </Modal>
    </>
  );
};

export default Settings;
