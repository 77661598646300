import "./orderlist.scss";
import { useEffect, useState } from "react";
import { DataGrid } from "@mui/x-data-grid";
import { Box, CircularProgress, Modal } from "@mui/material";
import { useMediaQuery } from "@mui/material";
import { DatePicker } from "antd";
import { useTranslation } from "react-i18next";
import { green, red } from "@mui/material/colors";
import CloseIcon from "@mui/icons-material/CloseOutlined";
import moment from "moment";
import ErrorIcon from "@mui/icons-material/ErrorOutlineOutlined";
import CheckIcon from "@mui/icons-material/CheckCircleOutlineOutlined";
import newRequest from "../../utils/newRequest";

const { RangePicker } = DatePicker;

const OrdersList = () => {
  const isMobile = useMediaQuery("(max-width:767px)");
  const isTablet = useMediaQuery("(min-width:768px) and (max-width:1023px)");
  const [t] = useTranslation();
  const [loading, setLoading] = useState(true);
  const [pageSize, setPageSize] = useState(10);
  const [orders, setOrders] = useState([]);
  const [filterdOrders, setFilterdOrders] = useState([]);
  const [order, setOrder] = useState(null);
  const [orderAnalysis, setOrderAnalysis] = useState(null);
  const [from, setFrom] = useState(null);
  const [to, setTo] = useState(null);
  const [page, setPage] = useState(1);
  const [total, setTotal] = useState(0);
  const [dates, setDates] = useState([]);
  const [error, setError] = useState(false);
  const direction = localStorage.getItem("direction");

  useEffect(() => {
    const getOrders = async () => {
      setLoading(true);
      try {
        const res = await newRequest.get(`/order?limit=10&page=${page}`);
        const orders = await res?.data?.data;
        setOrders(orders);
        setLoading(false);
        setTotal(res?.data?.total);
      } catch (error) {
        console.log(error);
        setLoading(false);
      }
    };
    getOrders();
  }, [page]);

  useEffect(() => {
    const getOrder = async () => {
      try {
        const res = await newRequest.get(`/analysis/${order?.id}`);
        setOrderAnalysis(res?.data);
      } catch (error) {
        console.log(error);
      }
    };

    order && getOrder();
  }, [order]);

  const handleFilter = async (e) => {
    e.preventDefault();
    setLoading(true);
    try {
      const res = await newRequest.get(`/analysis?from=${from}&to=${to}`);
      setFilterdOrders(res?.data?.data);
      setLoading(false);
    } catch (error) {
      console.log(error);
      setError(true);
      setDates(null);
      setLoading(false);
    }
  };

  const handleDateChange = (values) => {
    if (values) {
      const startDate = values[0].format("YYYY-MM-DD");
      const endDate = values[1].format("YYYY-MM-DD");
      setDates([startDate, endDate]);
      setFrom(startDate);
      setTo(endDate);
    } else {
      setDates(null);
    }
  };

  const handleOpenChange = (open) => {
    if (open && dates?.length === 2) {
      setDates([]);
    }
  };

  const actionColumn = [
    {
      field: "action",
      headerName: t("details"),
      width: isMobile ? 120 : isTablet ? 160 : 280,
      headerAlign: "center",
      flex: 1,
      align: "center",
      sortable: false, // Make the column unsortable
      filterable: false, // Make the column unfiltered
      renderCell: (params) => {
        return (
          <div className='cellAction'>
            <div
              className='editButton'
              onClick={() => {
                setOrder(params.row);
              }}
            >
              {t("details")}
            </div>
          </div>
        );
      },
    },
  ];

  const orderColumns = [
    {
      field: "orderNum",
      headerName: t("number"),
      width: isMobile ? 60 : isTablet ? 160 : 280,
      align: "center",
    },
    {
      field: "totalOrderPrice",
      headerName: t("total"),
      width: isMobile ? 120 : isTablet ? 160 : 280,
      align: "center",
      headerAlign: "center",
      flex: 1,
    },
    {
      field: "customer",
      headerName: t("customer"),
      width: isMobile ? 120 : isTablet ? 160 : 280,
      align: "center",
      headerAlign: "center",
      flex: 1,
      valueGetter: (params) => params.row.customer?.phone ?? "",
    },
    {
      field: "paymentMethodType",
      headerName: t("method"),
      width: isMobile ? 120 : isTablet ? 160 : 280,
      align: "center",
      headerAlign: "center",
      flex: 1,
      filterable: false,
    },

    {
      field: "createdAt",
      headerName: t("date"),
      type: "dateTime",
      width: isMobile ? 120 : isTablet ? 160 : 280,
      headerAlign: "center",
      flex: 1,
      align: "center",
      renderCell: (params) => moment(params.row.createdAt).format("YYYY-MM-DD"),
    },

    {
      field: "isPaid",
      headerName: t("status"),
      align: "center",
      width: isMobile ? 120 : isTablet ? 160 : 280,
      headerAlign: "center",
      flex: 1,
      sortable: false,
      filterable: false,
      renderCell: (params) => {
        return (
          <Box
            sx={{
              m: 1,
            }}
          >
            {params.row.isPaid ? (
              <CheckIcon
                sx={{
                  color: green[500],
                  width: 40,
                  height: 40,
                }}
              />
            ) : (
              <CloseIcon
                sx={{
                  color: red[500],
                  width: 40,
                  height: 40,
                }}
              />
            )}
          </Box>
        );
      },
    },
  ];

  return (
    <>
      <div className='datatable'>
        <div className='datatable-header'>
          <div className='date-container'>
            <div className='date-picker'>
              <RangePicker
                onChange={handleDateChange}
                onOpenChange={handleOpenChange}
                placeholder={[t("start"), t("end")]}
                value={
                  dates?.length === 2
                    ? [moment(dates[0]), moment(dates[1])]
                    : null
                }
              />
            </div>

            <div className='action-control'>
              <button
                onClick={() => {
                  setFilterdOrders([]);
                  setDates(null);
                }}
              >
                {t("cancel")}
              </button>
              <button onClick={handleFilter}>{t("search")}</button>
            </div>
          </div>
        </div>

        {loading ? (
          <article className='loader'>
            <CircularProgress className='circular-progress' size={40} />
          </article>
        ) : (
          <DataGrid
            className='datagrid'
            sx={{ width: "100%", height: "85%" }}
            rows={filterdOrders?.length > 0 ? filterdOrders : orders}
            columns={orderColumns.concat(actionColumn)}
            pagination
            paginationMode='server'
            pageSize={pageSize}
            rowCount={filterdOrders?.length > 0 ? filterdOrders.length : total}
            page={page - 1}
            onPageChange={(newPage) => setPage(newPage + 1)}
            disableVirtualization
            loading={loading}
          />
        )}
      </div>

      {/* Modal For Display Single Order */}
      <Modal open={order} className='modal' sx={{ direction: direction }}>
        <div className='single-order'>
          <div className='single-container'>
            <div className='top'>
              <div className='left'>
                <div className='icon-container' onClick={() => setOrder(null)}>
                  <CloseIcon className='close-icon' />
                </div>
                <h1
                  className='title'
                  style={{
                    color: "black",
                    fontSize: "20px",
                    fontWeight: "600",
                  }}
                >
                  {t("information")}
                </h1>
                <div className='item'>
                  <div className='details'>
                    <div className='detailItem'>
                      <span className='itemKey'>{t("customer")}</span>
                      <span
                        className='itemValue'
                        style={{
                          color: "#FF611D",
                          fontSize: "16px",
                          fontWeight: "600",
                        }}
                      >
                        {order?.customer?.phone}
                      </span>
                    </div>
                    <div className='detailItem'>
                      <span className='itemKey'>{t("date")}</span>
                      <span
                        className='itemValue'
                        style={{
                          color: "#FF611D",
                          fontSize: "16px",
                          fontWeight: "600",
                        }}
                      >
                        {moment(order?.createdAt).format("YYYY-MM-DD ")}
                      </span>
                    </div>
                    <div className='detailItem'>
                      <span className='itemKey'>{t("total")}</span>
                      <span
                        className='itemValue'
                        style={{
                          color: "#FF611D",
                          fontSize: "16px",
                          fontWeight: "600",
                        }}
                      >
                        {order?.totalOrderPrice}
                      </span>
                    </div>
                    <div className='detailItem'>
                      <span className='itemKey'>{t("method")}</span>
                      <span
                        className='itemValue'
                        style={{
                          color: "#FF611D",
                          fontSize: "16px",
                          fontWeight: "600",
                        }}
                      >
                        {order?.paymentMethodType}
                      </span>
                    </div>
                    <div className='detailItem'>
                      <span className='itemKey'>{t("orderNum")}</span>
                      <span
                        className='itemValue'
                        style={{
                          color: "#FF611D",
                          fontSize: "16px",
                          fontWeight: "600",
                        }}
                      >
                        {order?.orderNum}
                      </span>
                    </div>
                    <div className='detailItem'>
                      <span className='itemKey'>{t("status")}</span>
                      <span
                        className='itemValue'
                        style={{
                          color: "#FF611D",
                          fontSize: "16px",
                          fontWeight: "600",
                        }}
                      >
                        {order?.isPaid
                          ? t("successPayment")
                          : t("failedPayment")}
                      </span>
                    </div>
                    <div className='detailItem'>
                      <span className='itemKey'>{t("tabelNum")}</span>
                      <span
                        className='itemValue'
                        style={{
                          color: "#FF611D",
                          fontSize: "16px",
                          fontWeight: "600",
                        }}
                      >
                        {order?.tableNum}
                      </span>
                    </div>
                  </div>
                </div>
              </div>

              <div className='right'>
                <h1
                  className='title'
                  style={{
                    color: "black",
                    fontSize: "20px",
                    fontWeight: "600",
                  }}
                >
                  {t("orderStatus")}
                </h1>
                <div className='item'>
                  <div className='details'>
                    <div className='detailItem'>
                      <span className='itemKey'>{t("chef")}</span>
                      <span
                        className='itemValue'
                        style={{
                          color: "#FF611D",
                          fontSize: "16px",
                          fontWeight: "600",
                        }}
                      >
                        {Math.floor(orderAnalysis?.cheif?.cycleTime / 60000)}{" "}
                        {t("minute")}
                      </span>
                    </div>
                    <div className='detailItem'>
                      <span className='itemKey'>{t("waiter")}</span>
                      <span
                        className='itemValue'
                        style={{
                          color: "#FF611D",
                          fontSize: "16px",
                          fontWeight: "600",
                        }}
                      >
                        {Math.floor(orderAnalysis?.waiter?.cycleTime / 60000)}{" "}
                        {t("minute")}
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className='bottom'>
              <h1 className='title'>{t("products")}</h1>
              <table className='styled-table'>
                <thead>
                  <tr>
                    <th>#</th>
                    <th>{t("products")}</th>
                    <th>{t("price")}</th>
                    <th>{t("quantity")}</th>
                    <th>{t("photo")}</th>
                  </tr>
                </thead>
                <tbody>
                  {order?.cartItems?.map((item, index) => (
                    <tr key={item._id}>
                      <td>{index + 1}</td>
                      <td>{item?.product?.name}</td>
                      <td>{item?.product?.price}</td>
                      <td>{item?.quantity}</td>
                      <td>
                        <img
                          src={
                            process.env.REACT_APP_ASSETS_BASE_URL +
                            item?.product?.image
                          }
                          alt=''
                        />
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </div>
        </div>

        {/* <div className="single">
          <div className="singleContainer">
            <div className="top">
              <div className="left">
                <div className="icon-container" onClick={() => setOrder(null)}>
                  <CloseIcon className="close-icon" />
                </div>
                <h1
                  className="title"
                  style={{
                    color: "black",
                    fontSize: "20px",
                    fontWeight: "600",
                  }}
                >
                  {t("information")}
                </h1>
                <div className="item">
                  <div className="details">
                    <div className="detailItem">
                      <span className="itemKey">{t("customer")}</span>
                      <span
                        className="itemValue"
                        style={{
                          color: "#FF611D",
                          fontSize: "16px",
                          fontWeight: "600",
                        }}
                      >
                        {order?.customer?.phone}
                      </span>
                    </div>
                    <div className="detailItem">
                      <span className="itemKey">{t("date")}</span>
                      <span
                        className="itemValue"
                        style={{
                          color: "#FF611D",
                          fontSize: "16px",
                          fontWeight: "600",
                        }}
                      >
                        {moment(order?.createdAt).format("YYYY-MM-DD ")}
                      </span>
                    </div>
                    <div className="detailItem">
                      <span className="itemKey">{t("total")}</span>
                      <span
                        className="itemValue"
                        style={{
                          color: "#FF611D",
                          fontSize: "16px",
                          fontWeight: "600",
                        }}
                      >
                        {order?.totalOrderPrice}
                      </span>
                    </div>
                    <div className="detailItem">
                      <span className="itemKey">{t("method")}</span>
                      <span
                        className="itemValue"
                        style={{
                          color: "#FF611D",
                          fontSize: "16px",
                          fontWeight: "600",
                        }}
                      >
                        {order?.paymentMethodType}
                      </span>
                    </div>
                    <div className="detailItem">
                      <span className="itemKey"></span>
                      <span
                        className="itemValue"
                        style={{
                          color: "#FF611D",
                          fontSize: "16px",
                          fontWeight: "600",
                        }}
                      >
                        {order?.orderNum}
                      </span>
                    </div>
                    <div className="detailItem">
                      <span className="itemKey">{t("tabelNum")}</span>
                      <span
                        className="itemValue"
                        style={{
                          color: "#FF611D",
                          fontSize: "16px",
                          fontWeight: "600",
                        }}
                      >
                        {order?.tableNum}
                      </span>
                    </div>
                  </div>
                </div>
              </div>
              <div className="left">
                <h1
                  className="title"
                  style={{
                    color: "black",
                    fontSize: "20px",
                    fontWeight: "600",
                  }}
                >
                  {t("orderStatus")}
                </h1>
                <div className="item">
                  <div className="details">
                    <div className="detailItem">
                      <span className="itemKey">{t("chef")}</span>
                      <span
                        className="itemValue"
                        style={{
                          color: "#FF611D",
                          fontSize: "16px",
                          fontWeight: "600",
                        }}
                      >
                        {Math.floor(orderAnalysis?.cheif?.cycleTime / 60000)}{" "}
                        {t("minute")}
                      </span>
                    </div>
                    <div className="detailItem">
                      <span className="itemKey">{t("waiter")}</span>
                      <span
                        className="itemValue"
                        style={{
                          color: "#FF611D",
                          fontSize: "16px",
                          fontWeight: "600",
                        }}
                      >
                        {Math.floor(orderAnalysis?.waiter?.cycleTime / 60000)}{" "}
                        {t("minute")}
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="bottom">
              <h1
                className="title"
                style={{
                  color: "black",
                  fontSize: "20px",
                  fontWeight: "600",
                }}
              >
                {t("products")}
              </h1>
              <table className="styled-table">
                <thead>
                  <tr>
                    <th>#</th>
                    <th>{t("products")}</th>
                    <th>{t("price")}</th>
                    <th>{t("quantity")}</th>
                    <th>{t("photo")}</th>
                  </tr>
                </thead>
                <tbody>
                  {order?.cartItems?.map((item, index) => (
                    <tr key={item._id}>
                      <td>{index + 1}</td>
                      <td>{item?.product?.name}</td>
                      <td>{item?.product?.price}</td>
                      <td>{item?.quantity}</td>
                      <td>
                        <img
                          src={
                            process.env.REACT_APP_ASSETS_BASE_URL +
                            item?.product?.image
                          }
                          alt=""
                        />
                      </td>
                    </tr>
                  ))}
                  {order?.cartItems?.map((item, index) => (
                    <tr key={item._id}>
                      <td>{index + 1}</td>
                      <td>{item?.product?.name}</td>
                      <td>{item?.product?.price}</td>
                      <td>{item?.quantity}</td>
                      <td>
                        <img
                          src={
                            process.env.REACT_APP_ASSETS_BASE_URL +
                            item?.product?.image
                          }
                          alt=""
                        />
                      </td>
                    </tr>
                  ))}
                  {order?.cartItems?.map((item, index) => (
                    <tr key={item._id}>
                      <td>{index + 1}</td>
                      <td>{item?.product?.name}</td>
                      <td>{item?.product?.price}</td>
                      <td>{item?.quantity}</td>
                      <td>
                        <img
                          src={
                            process.env.REACT_APP_ASSETS_BASE_URL +
                            item?.product?.image
                          }
                          alt=""
                        />
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </div>
        </div> */}
      </Modal>

      {/* Modal For Error Message */}
      <Modal
        open={error}
        aria-labelledby='modal-modal-title'
        aria-describedby='modal-modal-description'
      >
        <div className='message-container'>
          <div className='message'>
            <div className='content'>
              <ErrorIcon className='icon ' />
              <p>{t("notFound")}</p>
            </div>
            <button
              onClick={() => {
                setError(false);
              }}
            >
              {t("ok")}
            </button>
          </div>
        </div>
      </Modal>
    </>
  );
};

export default OrdersList;
