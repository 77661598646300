import "./navbar.scss";
import LogoutIcon from "@mui/icons-material/Logout";
import React, { useContext } from "react";

import ReactFlagsSelect from "react-flags-select";
import { LanguageContext } from "../../context/LanguageContext";

const Navbar = ({ restaurant }) => {
  const { changeLanguage } = useContext(LanguageContext);
  const Lang = localStorage.getItem("i18nextLng");

  const handleLogout = async () => {
    localStorage.clear();
    window.location.reload();
  };
  return (
    <div className='navbar'>
      <div className='wrapper'>
        <div className='item' style={{ justifyContent: "space-between" }}>
          <span className='logo'>{restaurant?.name}</span>
          <img
            src={
              process.env.REACT_APP_ASSETS_BASE_URL + restaurant?.imageProfile
            }
            alt=''
          />
        </div>

        <div className='logout'>
          <ReactFlagsSelect
            countries={["SA", "US"]}
            customLabels={{
              SA: "ar",
              US: "en",
            }}
            onSelect={(code) => {
              changeLanguage(code);
            }}
            placeholder='Select Language'
            selected={Lang === "en" ? "US" : "SA"}
            className='language'
          />
          <LogoutIcon className='icon' onClick={handleLogout} />
        </div>
      </div>
    </div>
  );
};

export default Navbar;
