import axios from "axios";

const user = JSON.parse(localStorage.getItem("user"));
const token = user?.token;

const newRequest = axios.create({
  baseURL: process.env.REACT_APP_ENDPOINTS_BASE_URL,
  headers: {
    Authorization: `Bearer ${token}`,
    "Content-Type": "application/json",
  },
});

export default newRequest;
